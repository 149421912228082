<template>
  <IseiVerification tenant-alias="aifintech" financial-model-alias="prostir">
    <template v-slot:header-image>
      <b-img center src="/images/yedynyy-prostir.svg" style="width: 50%;" />
    </template>
    <template v-slot:header-text>
      ТОВ “Фінансова компанія “Єдиний простір”
    </template>
    <template v-slot:system-link>
      <b-card-text>
        Для укладання договору на
        <a href="https://www.onespace.in.ua" target="_blank"
          >отримання платіжних послуг</a
        >, просимо пройти електронну ідентифікацію за допомогою підсистеми
        верифікації ІСЕІ
      </b-card-text>
    </template>
  </IseiVerification>
</template>

<script>
import IseiVerification from "@/containers/IseiVerification.vue";
export default {
  name: "YedynyyProstir",
  components: {
    IseiVerification
  }
};
</script>
