<template>
  <b-container
    fluid
    class="registration wrapper auth-layout"
    style="height: 100vh;"
  >
    <div class="registration-form">
      <b-row style="height: inherit;" class="auth-layout">
        <b-col
          md="6"
          offset-md="3"
          style="display: flex; justify-content: center; align-items: center;"
        >
          <b-card no-body border-variant="0">
            <b-card-header header-bg-variant="white" header-border-variant="0">
              <slot name="header-image" />
            </b-card-header>

            <b-card-title class="text-center">
              <slot name="header-text" />
            </b-card-title>
            <b-card-body>
              <slot name="system-link" />

              <b-alert v-if="error" show variant="danger">
                Вам не потрібно проходити електронну ідентифікацію! Або ви
                помилилсь у написанні ЄДРПОУ/РНОКПП.
              </b-alert>

              <b-form>
                <b-form-group>
                  <div class="form-input-container">
                    <input
                      placeholder="ЄДРПОУ/РНОКПП"
                      class="form__input form__input_primary"
                      required
                      type="text"
                      v-model="code"
                      autocomplete="off"
                    />
                  </div>
                </b-form-group>
                <b-form-group>
                  <b-form-checkbox v-model="disabled"
                    >Я даю згоду на обробку моїх персональних
                    даних</b-form-checkbox
                  >
                </b-form-group>
                <b-button
                  :href="link"
                  class="form__button"
                  :disabled="!disabled || submitting || !code || error"
                >
                  <span v-if="!submitting"
                    >Пройти ідентифікацію за допомогою id.gov.ua</span
                  >
                  <b-spinner v-else small />
                </b-button>
              </b-form>
            </b-card-body>

            <b-card-footer footer-bg-variant="white" footer-border-variant="0">
              <b-container>
                <b-row>
                  <b-col md="6">
                    <b-img
                      center
                      src="/images/aml-logo.svg"
                      style="width: 40%;"
                    />
                  </b-col>
                  <b-col md="6">
                    <b-img center src="/images/isei.png" style="width: 48%;" />
                  </b-col>
                </b-row>
              </b-container>
            </b-card-footer>
          </b-card>
        </b-col>
      </b-row>
    </div>
    <div class="registration-video">
      <video
        class="aml-video"
        src="/video/aml-register.mp4"
        autoplay
        loop
      ></video>
    </div>
  </b-container>
</template>

<script>
import api from "@/api/api";

export default {
  name: "IseiVerification",
  props: {
    tenantAlias: {
      type: String,
      required: true
    },
    financialModelAlias: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      code: null,
      disabled: false,
      submitting: false,
      link: "",
      error: false
    };
  },
  methods: {
    getLink(params) {
      this.submitting = true;
      this.error = false;
      api
        .counterpartVerificationGenerateUrl(params)
        .then(({ data }) => {
          this.link = data.data.uri;
          this.submitting = false;
        })
        .catch(() => {
          this.error = true;
          this.submitting = false;
          this.link = "";
        });
    }
  },
  watch: {
    disabled() {
      if (this.disabled && this.code) {
        const params = {
          code: this.code,
          financial_model_alias: this.financialModelAlias,
          alias: this.tenantAlias
        };
        this.getLink(params);
      }
    },
    error() {
      if (this.error) {
        this.disabled = false;
      }
    }
  }
};
</script>
